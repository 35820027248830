.DateInput {
  width: 140px;
}

.DateInput_1 {
  margin: 0 10px 0 12px;
}

@media (min-width: 600px) {
  .DateInput {
    width: 216px;
  }
  .DateInput_1 {
    margin: 0 6px 0 6px;
  }
}
.DateInput_input {
  border: none;
  overflow: hidden;
  font-size: 16px;
  font-weight: 400;
}

.DateInput_input__focused {
  border-bottom: 2px solid #7f00e0;
}
.DateRangePickerInput {
  border: 1px solid #d6d6d6;
  border-radius: 8px;
}
.DateRangePicker_picker {
  z-index: 99999;
  border-radius: 30px;
}
.CalendarDay__default {
  color: #474747;
  border: none;
}
.CalendarDay__default:hover {
  color: #474747;
}
.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  top: 18px;
  line-height: 0.78;
  border-radius: 3px;
  padding: 6px 9px;
}
.DayPickerNavigation_leftButton__horizontalDefault {
  left: 22px;
}
.DayPickerNavigation_rightButton__horizontalDefault {
  right: 22px;
}
.DayPicker_weekHeader {
  color: #474747;
  padding: 0px 0px 0px 16px !important;
}
.DayPicker_weekHeader_li {
  width: 36px !important;
}
.CalendarMonth_caption {
  color: #474747;
  font-size: 16px;
  font-weight: 600 !important;
  text-transform: capitalize;
}
.CalendarDay {
  width: 37px !important;
  height: 28px !important;
}
.CalendarDay__selected {
  background: #d3eb1e;
  color: #292929;
  font-weight: 800;
  border: none;
}
.CalendarDay__selected_span,
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #d3eb1e;
  color: #292929;
  border: none;
  font-weight: 800;
}
.CalendarDay__selected_end,
.CalendarDay__lastDayOfWeek {
  border-radius: 0 8px 8px 0;
}

.CalendarDay__selected_start,
.CalendarDay__firstDayOfWeek {
  border-radius: 8px 0 0 8px;
}
.CalendarDay__selected:hover {
  background: #d3eb1e;
  font-weight: 700;
  color: #292929;
  border: none;
}
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #d3eb1e;
  color: #292929;
  border: none;
  font-weight: 800;
}
.DayPicker_transitionContainer {
  height: 279px !important;
  border-radius: 30px;
}
.CalendarMonth {
  padding: 0px 26px 0px 14px !important;
}
.CalendarMonth_table {
  border-collapse: separate;
  border-spacing: 0 4px;
}
.DayPicker {
  border-radius: 30px;
}
